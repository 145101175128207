import React from "react";
import "./Hero.css";
import heroimg from "../assets/img/hero.png";
import j1 from "../assets/svg/j1.svg";
import j2 from "../assets/svg/j2.svg";
import j3 from "../assets/svg/j3.svg";
import j4 from "../assets/svg/j4.svg";
import googleimg from "../assets/img/google-play-badge.png";
import appleimg from "../assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"

function Hero() {
  return (
    <div className='hero_cnt'>
      <div className='heroleft'>
        <div className='heroleftag'>
          <p className='herolefttagtext'>Simple and Secure Payments</p>
        </div>
        <h1 className='heroleftitle'>The New Era of Global Payments</h1>
        <p className='heroleftparagraph'>
          Easily collect instant payments in one time or automatic recurring
          payments. No rush, stress, or hight costs.
        </p>

        <div className='heroleftbuttons'>
          <img src={googleimg} alt="" className='heroleftbtnget'/>
          <img src={appleimg} alt="" className='heroleftbtnget'/>
        </div>

        <p className='herolefttrusted'>Trusted 13,000+ Businesses Worldwide</p>
        <div className='herolefttrustimgs'>
          <img src={j1} alt='' />
          <img src={j2} alt='' />
          <img src={j3} alt='' />
          <img src={j4} alt='' />
        </div>
      </div>
      <div className='heroright'>
        <img src={heroimg} alt='' />
      </div>
    </div>
  );
}

export default Hero;
