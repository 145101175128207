import React from 'react';
import "./Sectionfour.css"
import missionimg from "../assets/img/missionbg.png"

function Sectionfour() {
  return (
    <div className='hero_cnt' id='resources'>
    <div className='heroleft'>
      <div className='heroleftag'>
        <p className='herolefttagtext'>Our Mission</p>
      </div>
      <h1 className='heroleftitle'>Meet Money Without Borders</h1>
      <p className='heroleftparagraph'>
       Our dream is for businesses to be able to transact anywhere seamlessly. That means money without borders: moving it instantly, transparently, conveniently, and eventually for free.
      </p>

      <div className='heroleftbuttons'>
        <button className='learnbtn'>Learn Our Mission</button>
       
      </div>

     
    </div>
    <div className='heroright'>
      <img src={missionimg} alt='' />
    </div>
  </div>
  )
}

export default Sectionfour