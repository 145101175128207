import React from "react";
import "./Sectionone.css";
import sectiononeimg from "../assets/img/sec2bg.png";
import { autoicon, feesicon, latepayicon } from "../utilities/tools";

function Sectionone() {
  return (
    <div className='sectionone_cnt' id='home'>
      <div className='sectlsl'>
        <div className='sectiononetop'>
          <div className='sectiononetopimage'>
            <img src={sectiononeimg} alt='' />
          </div>

          <div className='sectiononetopright'>
            <div className='scbody'>
              <div className='heroleftag'>
                <p className='herolefttagtext'>Why Tespay</p>
              </div>
              <h1 className='sectiononetoptitle'>
                Make Payments But Without The Hassle
              </h1>
              <p className='sectiononetopparagraph'>
                Tespay lets businesses send and receive payments directly
                between bank accounts. Whether domestic or international,
                one-off or recurring, scheduled or instant. Tespay does it all
                quick, easy, and secure.
              </p>
            </div>
          </div>
        </div>
        <div className='sectiononebottomitems'>
          <div className='sectiononeitems'>
            <div className='sectiononeitemsicons'>{latepayicon}</div>

            <h3 className='sectiononeitemstitle'>End Late Payments</h3>
            <p className='sectiononeitemsparagraph'>
              It's easy for your customers to make one-time instant payments or
              set up for automatic payments.
            </p>
          </div>
          <div className='sectiononeitems'>
            <div className='sectiononeitemsicons'>{autoicon}</div>

            <h3 className='sectiononeitemstitle'>Admin Automation</h3>
            <p className='sectiononeitemsparagraph'>
              Easily view the status of any payment, anywhere and anytime,
              connect Tespay, reconcilliation is automatic.
            </p>
          </div>
          <div className='sectiononeitems'>
            <div className='sectiononeitemsicons'>{feesicon}</div>

            <h3 className='sectiononeitemstitle'>Forget Expensive Fees</h3>
            <p className='sectiononeitemsparagraph'>
              Tespay collects direct bank payments with no card fees, so no
              admin time is wasted.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sectionone;
