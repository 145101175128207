import React, { useState } from "react";
import "./Navbar.css";
import { menuicon, closemenu } from "../utilities/tools";
import logo from "../assets/img/logo.svg";
import googleimg from "../assets/img/google-play-badge.png";
import appimg from "../assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

function Navbar() {
  const [showmob, setshowmob] = useState(false);

  return (
    <div className='navbar_cntt'>
      <div className='navbar_body'>
        <div className='navbarleft'>
          <img src={logo} alt='' />
        </div>

        <div className='navbarmiddle'>
          <p
            className='navbarmiddlelink'
            onClick={() => {
              const element = document.getElementById("home");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Why Tespay
          </p>
          <p
            className='navbarmiddlelink'
            onClick={() => {
              const element = document.getElementById("products");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Products
          </p>
          <p
            className='navbarmiddlelink'
            onClick={() => {
              const element = document.getElementById("company");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Company
          </p>
          <p
            className='navbarmiddlelink'
            onClick={() => {
              const element = document.getElementById("resources");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Resources
          </p>
        </div>

        <div className='navbarright'>
          <button className='menuicon' onClick={() => setshowmob(!showmob)}>
            {showmob ? (
              <div className=''>{closemenu}</div>
            ) : (
              <div className=''>{menuicon}</div>
            )}
          </button>
          <img src={googleimg} alt='' className='authlogine' />
          <img src={appimg} alt='' className='authlogine' />
        </div>
      </div>
      {showmob && (
        <>
          {/* <div className='doverlay' onClick={() => setshowmob(false)} /> */}
          <div className='navbarbottom'>
            <div className='navbarbottomlinks'>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("home");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Why Tespay
              </p>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("products");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Products
              </p>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("company");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Company
              </p>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("resources");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Resources
              </p>
            </div>

            <div className='navbarbottombtns'>
              <img src={googleimg} alt='' className='authlogineeas' />
              <img src={appimg} alt='' className='authlogineeas' />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Navbar;
