import React from "react";
import "./Sectionthree.css";
import { stariconfill } from "../utilities/tools";
import prof1 from "../assets/img/prof1.png";
import prof2 from "../assets/img/prof2.png";
import j1 from "../assets/svg/j1.svg";
import j2 from "../assets/svg/j2.svg";

function Sectionthree() {
  return (
    <div className='sectionthreecnt' id="company">
      <div className='sectiontwocnttop'>
        <div className='sectiontwotag'>
          <p className='sectiontwotagtext'>Testimonial</p>
        </div>
        <h1 className='sectiontwotitle'>What Our Customers Are Saying</h1>
        <p className='sectiontwoparagraph'>
          From individuals to multi-national corporations. Tespay helps
          thousands of businesses with their payments everyday.
        </p>
      </div>
      <div className='sectionthreebottomtwo'>
        <div className='sectionthreebtmitems'>
          <div className='sectionthreebtmitemsleft'>
            <div className='sectionthreebtmstars'>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
            </div>
            <p className='sectionthreebtmcomment'>
              "As a business owner, Tespay, makes me feel secure. I never wake
              up in the middle of the night worrying about payments"
            </p>
            <div className='sectionthreebtmprof'>
              <img src={prof1} alt='' />
              <div className='sectthenames'>
                <h3 className='sectthenam'>Tynisha Obey</h3>
                <p className='sectstext'>Co-Founder & CEO, Capterra</p>
              </div>
            </div>
          </div>
          <div className='sectionthreebtmitemsright'>
            <img src={j1} alt='' />
          </div>
        </div>
        <div className='sectionthreebtmitems'>
          <div className='sectionthreebtmitemsleft'>
            <div className='sectionthreebtmstars'>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
              <div className='stbss'>{stariconfill}</div>
            </div>
            <p className='sectionthreebtmcomment'>
              "Would confidently recommend it to anyone looking for a
              cost-effective way to transfer money internationally."
            </p>
            <div className='sectionthreebtmprof'>
              <img src={prof2} alt='' />
              <div className='sectthenames'>
                <h3 className='sectthenam'>Jamel Eusebio</h3>
                <p className='sectstext'>President, Gizmodo</p>
              </div>
            </div>
          </div>
          <div className='sectionthreebtmitemsright'>
            <img src={j2} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sectionthree;
