import React from "react";
import "./Sectiontwo.css";
import sec2bg from "../assets/img/sec2bt.png";
import { arrowtoprighticon } from "../utilities/tools";

function Sectiontwo() {
  return (
    <div className='sectiontwobody' id="products">
      <div className='sectiontwocnt'>
        <div className='sectiontwocnttop'>
          <div className='sectiontwotag'>
            <p className='sectiontwotagtext'>Features</p>
          </div>
          <h1 className='sectiontwotitle'>
            All The Features You Need In One Place
          </h1>
          <p className='sectiontwoparagraph'>
            Tincidunt mi adipiscing numc mauris lobortis. Eget porta risus eu
            faucibus purus. Donec mollis neque amet
          </p>
        </div>

        <div className='sectiontwocntbottom'>
          <div className='sectiontwocntitems'>
            <div className='sectiontwocnttitemstop'>
              <h2 className='sectiontwocntitemstitle'>
                Instant One-off Payments Quickly
              </h2>
              <p className='sectiontwocntitemsparagraph'>
                Collect one-off payments instantly with Tespay. Direct from your
                customer's bank account to yours.
              </p>
              <button className='lernmorebtn'>
                Learn More {arrowtoprighticon}
              </button>
            </div>
          </div>
          <div className='sectiontwocntitems'>
            <div className='sectiontwocnttitemstop'>
              <h2 className='sectiontwocntitemstitle'>
                Automatically Recurring Payments
              </h2>
              <p className='sectiontwocntitemsparagraph'>
                Automatically collect payments on the due date via direct bank
                even if the date or amount changes, perfect
              </p>
              <button className='lernmorebtn'>
                Learn More {arrowtoprighticon}
              </button>
            </div>
          </div>
        </div>

        <div className='sectiontwocntbottomtwo'>
          <div className='sectwocntleft'>
            <h2 className='sectiontwocntitemstitle'>
              International Money Payments
            </h2>
            <p className='sectiontwocntitemsparagraph stcip'>
              Collect payments from 50+ countries. Either into a local bank
              account, or your existing international account
            </p>
            <button className='lernmorebtn'>
              Learn More {arrowtoprighticon}
            </button>
          </div>
          <div className='sectwocntright'>
            <img src={sec2bg} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sectiontwo;
