import React from "react";
import bgimage from "../assets/img/bgokay.png";
import googleimg from "../assets/img/google-play-badge.png";
import appimg from "../assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import "./Sectionfive.css";

function Sectionfive() {
  return (
    <div className='sectionfivecnt'>
      <div
        className='sectionfivebody'
        style={{ backgroundImage: `url(${bgimage})` }}
      >
        <div className='sectionfivebodycontent'>
          <h1 className='sectionfivebodycontentext'>
            Ready to Get Started for Better Payments
          </h1>
          <div className=' okassas'>
          <img src={googleimg} alt="" className='authlogineeas'/>
          <img src={appimg} alt="" className='authlogineeas'/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sectionfive;
